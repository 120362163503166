@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --blue: 218 89% 55%;
    --background: 240 22% 14%;
    --foreground: 0 0% 100%;
    --card: 0 0% 10%;
    --card-foreground: 0 0% 100%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 100%;
    --primary: 292 64% 70%;
    --primary-foreground: 0 0% 100%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 100%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 100%;
    --destructive: 360 74% 62%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 10% 24%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --nav-panel: 240 16% 15%;
    --nav-panel-foreground: 240 3% 56%;
    --progress: 227 15% 25%;
    --drawer: 226 21% 16%;
    --game-card: 235 23% 10%;
    --badge: 240 12% 23%;
    --badge-border: 240 8% 31%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-body;
    @apply bg-background text-foreground;

    font-synthesis-weight: none;
    text-rendering: optimizelegibility;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step::before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];

    content: counter(step);
  }

  .chunk-container {
    @apply shadow-none;
  }

  .chunk-container::after {
    content: '';

    @apply absolute -inset-4 shadow-xl rounded-xl border;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media (max-width <= 640px) {
  .container {
    @apply px-4;
  }
}
