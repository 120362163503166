html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

body {
  overflow: hidden;
}
