/* EuclidGalactic */
@font-face {
  font-family: EuclidGalactic;
  font-weight: 400;
  src: url('~shared/assets/fonts/EuclidGalactic/EuclidGalactic-Regular.woff2') format('woff2');
}

@font-face {
  font-family: EuclidGalactic;
  font-weight: 500;
  src: url('~shared/assets/fonts/EuclidGalactic/EuclidGalactic-Medium.woff2') format('woff2');
}

@font-face {
  font-family: EuclidGalactic;
  font-weight: 600;
  src: url('~shared/assets/fonts/EuclidGalactic/EuclidGalactic-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: EuclidGalactic;
  font-weight: 700;
  src: url('~shared/assets/fonts/EuclidGalactic/EuclidGalactic-Bold.woff2') format('woff2');
}

@font-face {
  font-family: EuclidGalactic;
  font-weight: 800;
  src: url('~shared/assets/fonts/EuclidGalactic/EuclidGalactic-ExtraBold.woff2') format('woff2');
}

/* Inter */
@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url('~shared/assets/fonts/Inter/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: url('~shared/assets/fonts/Inter/Inter-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('~shared/assets/fonts/Inter/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url('~shared/assets/fonts/Inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('~shared/assets/fonts/Inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url('~shared/assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url('~shared/assets/fonts/Inter/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 800;
  src: url('~shared/assets/fonts/Inter/Inter-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 900;
  src: url('~shared/assets/fonts/Inter/Inter-Black.woff2') format('woff2');
}
